import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CreateItemModalComponent} from '../create-item-modal/create-item-modal.component';
import {DetailItemComponent} from '../detail-item/detail-item.component';
import {Item} from '../../model';
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  private collection: AngularFirestoreCollection<Item>;
  items: Observable<Item[]>;

  constructor(private firestore: AngularFirestore, private dialog: MatDialog, private storage: AngularFireStorage) {
    this.collection = firestore.collection<Item>('items');
    this.items = this.collection.valueChanges({
      idField: 'idField'
    });
  }

  ngOnInit(): void {
  }

  // image(item: Item) {
  //   return this.storage.ref(item.idField).getDownloadURL();
  // }

  add() {
    let dialogRef = this.dialog.open(CreateItemModalComponent, {
      width: '600px'
    });
  }

  showDetail(item: Item) {
    let dialogRef = this.dialog.open(DetailItemComponent, {
      width: '600px',
      data: {
        item: item
      }
    });
  }
}
