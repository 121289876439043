<div class="item-grid">
<ng-container *ngFor="let item of items | async">
  <mat-card [routerLink]="['items', item.idField]">
    <mat-card-subtitle>
      {{item.name}}
    </mat-card-subtitle>
    <mat-card-content>
      <img src="http://via.placeholder.com/160x160" [alt]="item.name" />
<!--      <img [src]="item.downloadURL" *ngIf="item.downloadURL"  [alt]="item.name" class="grid-image" />-->
    </mat-card-content>
  </mat-card>
</ng-container>
  <mat-card>
    <mat-card-subtitle>
      &nbsp;
    </mat-card-subtitle>
    <mat-card-content>
      <a (click)="add()"><mat-icon class="add">add</mat-icon></a>
    </mat-card-content>
  </mat-card>
</div>
