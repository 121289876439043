<mat-toolbar color="primary">
  <img src="assets/logo-compact.png" height="64" [routerLink]="'/'" class="header" />
  <span [routerLink]="'/'" class="header">item list</span>
  <span class="spacer"></span>

  <div>
    <div *ngIf="auth.user | async as user; else showLogin">
      <span class="username">Signed in as: {{ user.displayName ? user.displayName : user.phoneNumber }}</span>
      <button mat-button (click)="logout()">Logout</button>
    </div>
    <ng-template #showLogin>
      <button mat-button [routerLink]="'login'">Login</button>
    </ng-template>
  </div>

</mat-toolbar>

<div class="content">
  <router-outlet></router-outlet>
</div>
