import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-create-item-modal',
  templateUrl: './create-item-modal.component.html',
  styleUrls: ['./create-item-modal.component.scss']
})
export class CreateItemModalComponent implements OnInit {

  name: FormControl;
  srcResult: any;

  constructor(private dialogRef: MatDialogRef<CreateItemModalComponent>, private fb: FormBuilder, private firestore: AngularFirestore, private storage: AngularFireStorage) {
    this.name = fb.control('');
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close({});
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };

      reader.readAsDataURL(inputNode.files[0]);
    }
  }

  validate() {
    return this.srcResult && this.name.value;
  }

  async submit() {
    if(this.validate()) {
      const reference = await this.firestore.collection('items').add({
        name: this.name.value
      });
      const inputNode: any = document.querySelector('#file');
      const fileRef = this.storage.ref(reference.id);
      const uploadTask = fileRef.put(inputNode.files[0]);
      uploadTask.catch(error => {
        reference.delete();
        console.log(error);
      }).then(() => {

      });

      uploadTask.snapshotChanges().pipe(finalize(() => {
        console.log("Finalized");
        fileRef.getDownloadURL().subscribe(downloadURL => {
          reference.update({
            downloadURL: downloadURL
          });
        });

      })).subscribe();

      this.dialogRef.close({});
    }
  }
}
