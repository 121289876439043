import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Item} from './model';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {first, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItemResolverService implements Resolve<Item> {

  constructor(private firestore: AngularFirestore) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Item> {
    const id = route.params['id'];
    return this.firestore.doc<Item>('items/' + id).valueChanges()
      .pipe(first())
      .pipe(
        map((item) => {
          console.log(item);
          if (item) {
            item.idField = id;
            return item; // this doesen't resolve the router guard.
          }
        }));
  }
}
