import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GridComponent} from './item/grid/grid.component';
import {DetailItemComponent} from './item/detail-item/detail-item.component';
import {ItemResolverService} from './item-resolver.service';
import {LoginComponent} from './login/login.component';


const routes: Routes = [
  {
    path: '',
    component: GridComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'items/:id',
    component: DetailItemComponent,
    resolve: {
      item: ItemResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
