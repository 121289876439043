<h1 mat-dialog-title>Add new item</h1>
<div mat-dialog-content class="upload-dialog">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [formControl]="name" placeholder="Short description">
  </mat-form-field>

  <div>
    <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" accept="image/*">
    <img *ngIf="!srcResult" src="assets/Default_Image_Thumbnail.png" alt="uploaded image" (click)="fileInput.click()" width="100%" />
    <img *ngIf="srcResult" [src]="srcResult" alt="uploaded image" (click)="fileInput.click()" width="100%" />
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeModal()">No Thanks</button>
  <button mat-button (click)="submit()" [disabled]="!validate()">Ok</button>
</div>

