import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Item} from '../../model';

@Component({
  selector: 'app-list-items',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.scss']
})
export class ListItemsComponent implements OnInit {

  private collection: AngularFirestoreCollection<Item>;
  items: Observable<any[]>;

  constructor(private firestore: AngularFirestore) {
    this.collection = firestore.collection<Item>('items');
    this.items = this.collection.valueChanges({
      idField: 'idField'
    });
  }

  ngOnInit(): void {
  }

  async delete(item: Item) {
    console.log(item);
    await this.collection.doc<Item>(item.idField).delete();
  }

}
