// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBlUvBA5sxCB4b-6Vrun_k9Bva1JKuDj6Y",
    authDomain: "divider-app.firebaseapp.com",
    databaseURL: "https://divider-app.firebaseio.com",
    projectId: "divider-app",
    storageBucket: "divider-app.appspot.com",
    messagingSenderId: "657746518518",
    appId: "1:657746518518:web:395bc874acddd0b1a227d7"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
