import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {MatDialog} from '@angular/material/dialog';
import {LoginComponent} from './login/login.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public auth: AngularFireAuth, private dialog: MatDialog) {

  }

  login() {
    this.dialog.open(LoginComponent, {
      width: '200px'
    });
  }
  logout() {
    this.auth.signOut();
  }

  ngOnInit(): void {
  }
}
