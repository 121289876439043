<div class="details">
  <div>
    <img [src]="item.downloadURL" width="600" [alt]="item.name"/>
  </div>
  <div>
    <h1>{{item.name}}</h1>
    <ul>
      <li *ngFor="let comment of comments | async">
        {{comment.comment}}
      </li>
    </ul>
    <mat-form-field>
      <mat-label>Comment</mat-label>
      <input matInput [formControl]="comment" placeholder="Comments">
    </mat-form-field>
    <button mat-button (click)="addComment()" cdkFocusInitial>add comment</button>
  </div>
</div>
<div>
  <button mat-button (click)="delete()" cdkFocusInitial color="warn">delete</button>
</div>

