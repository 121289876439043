import {Component, OnInit} from '@angular/core';
import {Comment, Item} from '../../model';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';
import {FormBuilder, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-detail-item',
  templateUrl: './detail-item.component.html',
  styleUrls: ['./detail-item.component.scss']
})
export class DetailItemComponent implements OnInit {

  private collection: AngularFirestoreCollection<Item>;

  item: Item;
  comments: Observable<Comment[]>;
  comment: FormControl;

  constructor(private firestore: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              private storage: AngularFireStorage,
              private fb: FormBuilder) {
    this.collection = firestore.collection<Item>('items');

    this.comment = fb.control('');

  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.item = data.item;

      this.storage.ref(this.item.idField).getDownloadURL();

      this.comments = this.collection.doc<Item>(this.item.idField).collection<Comment>('comments').valueChanges({
        idField: 'idField'
      });
    });
  }

  async addComment() {
    this.collection.doc<Item>(this.item.idField).collection('comments').add({
      comment: this.comment.value
    })
      .then(() => this.comment.reset())
      .catch((error) => this.snackBar.open(error, 'error', {duration: 2000}));
  }

  async delete() {
    this.collection.doc<Item>(this.item.idField).delete()
      .then(() => this.router.navigate(['/']))
      .catch((error) => this.snackBar.open(error, 'error', {duration: 2000}));

  }
}
