<mat-card>
  <mat-card-title>
    Items
  </mat-card-title>
  <mat-card-content>
    <ul>
      <li *ngFor="let item of items | async">
        {{ item.name }}
        <a (click)="delete(item)">
          <mat-icon>delete</mat-icon>
        </a>
      </li>
    </ul>
  </mat-card-content>
</mat-card>
